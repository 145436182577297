import * as React from 'react'
import { graphql } from 'gatsby'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import Parser from 'html-react-parser'
// Components
import Layout from '../components/layout'
import Seo from '../components/seo'
import BackgroundImgCard from '../components/BackgroundImgCard'
import Cta from '../components/Cta'
import Cover from '../components/Cover'
import TrustedBy from '../components/TrustedBy'
import Carousel from '../components/Carousel'
import Columns from '../components/Columns'
import Slider from '../components/Slider'
import ProductsCard from '../components/ProductsCard'
import TestimonialsIspo from '../components/TestimonialsIspo'
import Video from '../components/Video'

import { Container, TableHead, Typography, Table, TableBody, TableRow, TableCell } from '@mui/material'
// Content
import {
  ispoTextrendsAwardsContent,
  textileProductsContent,
  textileObpProductsContent,
  apparelProductsContent,
} from '../content/ispoTextrendsAwards'
import { logoSlideContent } from '../content/logoSlide'
// Styling
import '../styles/ispo.scss'
// Images
import paolaGalilei from '../assets/images/ispo/paola-galilei.png'
import trendbookBook from '../assets/images/ispo/trendbook-ss25-cover-image.jpg'
const IspoTextrendsAwardsPage = () => {
  const { t } = useTranslation()
  return (
    <Layout headerWhite={true}>
      <Cover content={ispoTextrendsAwardsContent(t).cover} />
      <Container maxWidth='xl' sx={{ textAlign: 'center' }} className='about-container pt-s-48 pb-s-48 mb-s-32'>
        <Typography variant='h2' sx={{ color: 'bluishGrey.ten', mb: 5 }} className='pb-s-12'>
          {t('ABOUT')}
        </Typography>
        <Typography sx={{ mb: 3 }}>{t('ABOUT_TEXT')}</Typography>
      </Container>
      <div className='full-div-team w-100 pt-s-48 pb-s-48 mb-s-64'>
        <Container
          maxWidth='xl'
          disableGutters
          sx={{ textAlign: 'center' }}
          className='textile-products-section mb-s-32'
        >
          <b className='sub-headline text-grey-8'>{t('OVERALL_BEST_PRODUCT')}</b>
          <Typography variant='h2' sx={{ color: 'bluishGrey.ten', mb: 4 }} className='pt-s-8'>
            {t('10_ANNIVERSARYOBP')}
          </Typography>
          {textileObpProductsContent(t).slider && (
            <Slider content={textileObpProductsContent(t).slider}>
              <ProductsCard />
            </Slider>
          )}
        </Container>
      </div>
      <Video content={ispoTextrendsAwardsContent(t).video} />
      <div className='full-div-team w-100 pt-s-48 pb-s-48 mb-s-64'>
        <Container
          maxWidth='xl'
          disableGutters
          sx={{ textAlign: 'center' }}
          className='textile-products-section mb-s-32'
        >
          <b className='sub-headline text-grey-8'>{t('TEXTILE_EDITION')}</b>
          <Typography variant='h2' sx={{ color: 'bluishGrey.ten', mb: 4 }} className='pt-s-8'>
            {t('DISCOVER_BEST_PRODUCTS')}
          </Typography>
          {textileProductsContent(t).slider && (
            <Slider content={textileProductsContent(t).slider}>
              <ProductsCard />
            </Slider>
          )}
        </Container>
        <Container
          maxWidth='xl'
          disableGutters
          sx={{ textAlign: 'center' }}
          className='textile-products-section mb-s-32'
        >
          <b className='sub-headline text-grey-8'>{t('APPAREL_EDITION')}</b>
          <Typography variant='h2' sx={{ color: 'bluishGrey.ten', mb: 4 }} className='pt-s-8'>
            {t('DISCOVER_BEST_PRODUCTS')}
          </Typography>
          <Slider content={apparelProductsContent(t).slider}>
            <ProductsCard />
          </Slider>
        </Container>
      </div>
      <TestimonialsIspo />
      <div className='sliderIspo'>
        <Columns content={ispoTextrendsAwardsContent(t).columns} />
      </div>
      <Cta content={ispoTextrendsAwardsContent(t).keyDates} />
      <div className='full-div-team w-100 pt-s-48 pb-s-48 mb-s-64'>
        <Container maxWidth='lg' sx={{ textAlign: 'center' }}>
          <Typography variant='h2' sx={{ color: 'bluishGrey.ten', mb: 5 }} className='pb-s-12'>
            {t('MEET_THE_TEAM')}
          </Typography>
           {/*<div className='d-flex team-section justify-content-around'>
            <div className='d-flex flex-column align-items-center margin-mobile'>
              <img height={240} src={paolaGalilei} alt={t('PAOLA_GALILEI')} />
              <Typography variant='body1Bold' sx={{ color: 'bluishGrey.eight', mb: 5 }} className='m-0 pb-s-20 pt-s-20'>
                {t('PAOLA_GALILEI')}
              </Typography>
              <b className='sub-headline text-grey-8'>{t('PAOLA_GALILEI_JOB')}</b>
            </div>
          </div>*/}
          <Typography variant='body2' sx={{ color: 'bluishGrey.ten', mb: 5 }} className='m-0 pb-s-20 pt-s-40 email'>
            {Parser(t('QUESTIONS_EMAIL'))}
          </Typography>
        </Container>
      </div>
      <div className='d-flex flex-column mt-auto align-items-center bottom-section'>
        <TrustedBy mode={'dark'} disposition={'row'} text={t('BUSINESSES')} show={false} />
        <Carousel className='mt-s-28' content={logoSlideContent('big', 'default', true).carousel} />
      </div>
    </Layout>
  )
}
export const Head = ({ location }) => (
  <Seo
    title='ISPO Textrends Awards'
    description='The ISPO Textrends Award recognizes the most innovative ready-made garments, accessories, fibers, fabrics, and component materials in the sports & outdoor sector.'
    pathname={location.pathname}
  />
)
export const query = graphql`
  query {
    locales: allLocale {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
export default IspoTextrendsAwardsPage
