import styled from 'styled-components'

export const TestimonialsIspo = styled.section`
  display: flex;
  align-items: center;
  margin: 64px 16px;
  & .MuiContainer-root {
    border-radius: 24px;
    background: ${(props) => (props.mode === 'dark' ? '#071027' : '#F9FAFC')};
  }
`
