// Base
import * as React from 'react'
import { useTranslation } from 'gatsby-plugin-react-i18next'

// Components
import { Container, Typography } from '@mui/material'
import Carousel from '../Carousel'

// Content
import { testimonialsIspoContent } from './TestimonialsIspo.content'

// Styles
import * as S from './TestimonialsIspo.styles'

const TestimonialsIspo = (props) => {
  const { t } = useTranslation()

  return (
    <S.TestimonialsIspo {...props}>
      <Container maxWidth='xl' sx={{ textAlign: 'center', py: { xs: 6, md: 8 } }}>
        <Typography variant='subhead' sx={{ display: 'block', color: 'bluishGrey.eight', mb: 2 }}>
          {t('TESTIMONIALS')}
        </Typography>
        <Typography variant='h2' sx={{ color: props.mode === 'dark' ? 'bluishGrey.two' : 'bluishGrey.ten', mb: 3 }}>
          {t('WHAT_THE_PARTICIPANTS_SAY')}
        </Typography>
        <Carousel content={testimonialsIspoContent(t).carousel} {...props} />
      </Container>
    </S.TestimonialsIspo>
  )
}
export default TestimonialsIspo
