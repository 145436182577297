// Base
import * as React from 'react'
import Parser from 'html-react-parser'

// Components
import { Box, Container, Typography } from '@mui/material'

const Video = ({ content }) => {
  return (
    <Box sx={{ my: 8 }}>
      <Container disableGutters maxWidth='xl' sx={{ textAlign: 'center' }}>
        {content?.subhead && (
          <Typography variant='subhead' sx={{ color: 'bluishGrey.eight' }}>
            {content.subhead}
          </Typography>
        )}
        {content?.title && (
          <Typography variant='h2' sx={{ mb: 5 }}>
            {content.title}
          </Typography>
        )}
        {content?.subtitle && (
          <Typography variant='h3' sx={{ mb: 5 }}>
            {Parser(content.subtitle)}
          </Typography>
        )}
        {content?.description && <Typography component='div'>{Parser(content.description)}</Typography>}
        {content?.id && (
          <Container disableGutters maxWidth='md'>
            <Box className='ratio ratio-16x9' sx={{ overflow: 'hidden', borderRadius: 1.5 }}>
              {content?.platform === 'youtube' ? (
                <iframe
                  title={content.id}
                  src={`https://www.youtube.com/embed/${content.id}?showinfo=0&iv_load_policy=3&modestbranding=1&rel=0&controls=0`}
                  allowfullscreen
                ></iframe>
              ) : (
                <iframe title={content.id} src='https://player.vimeo.com/video/928435780'></iframe>
              )}
            </Box>
          </Container>
        )}
      </Container>
    </Box>
  )
}
export default Video
