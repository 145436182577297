// Assets
import polyCoreLogo from '../../assets/images/ispo/testimonials/polycore-logo.png';
import gabriellaPolyCore from '../../assets/images/ispo/testimonials/gabriella-whittaker-testimonials.jpg';
import TransfersLogo from '../../assets/images/ispo/testimonials/3t-transfers-technologies-for-textile-logo.png';
import dovileTransfers from '../../assets/images/ispo/testimonials/dovile-kudule-testimonials.jpg';
import uabTransferLogo from '../../assets/images/ispo/testimonials/uab-heat-transfers-company-logo.png';
import romenaUabTransfer from '../../assets/images/ispo/testimonials/romena-varnaityte-testimonials.jpg';

export const testimonialsIspoContent = (t) => {
  return {
    carousel: {
      type: 'testimonialCard',
      extraClassCarousel: 'testimonialCard-Carousel',
      extraClassSlide: 'testimonialCard-Slide',
      slidesConfig: {
        duration: 6500,
        transitionDuration: 1200,
        transitionDurationDesktop: 200,
        autoplay: 'big desktop',
        pauseOnHover: true,
        infinite: false,
        slidesToScroll: 3,
        slidesToScrollDesktop: 1,
        canSwipe: 'all',
        indicators: 'mobile',
        slidesMobile: 1,
        slidesTablet: 3,
        slidesDesktop: 3,
        slidesBigDesktop: 3,
      },
      cards: [
        {
          logo: {
            src: polyCoreLogo,
            alt: 'ISPO Textrends Award',
          },
          avatar: {
            src: gabriellaPolyCore,
            alt: 'Gabriella Whittaker',
            name: 'Gabriella Whittaker ',
            company: 'Director of Sustainability',
            companyName: 'PolyCore'
          },
          description:
          "The ISPO Textrends Award is a great way to know new innovations. Sustainability is what we like the most, every company is trying to come up with a sustainable factor.",
        },
        {
          logo: {
            src: uabTransferLogo,
            alt: 'Oeko-Tex',
          },
          avatar: {
            src: dovileTransfers,
            alt: 'Dovilė Kudulė',
            name: 'Dovilė Kudulė',
            company: 'Office Manager',
            companyName: 'UAB Heat Transfers Company'
          },
          description:
          "It's the best award! We like to follow the market development and try new things. We must participate to revamp our company.",
        },
        {
          logo: {
            src: TransfersLogo,
            alt: 'Bangladesh Apparel Exchange',
          },
          avatar: {
            src: romenaUabTransfer,
            alt: 'Romena Varnaityte',
            name: 'Romena Varnaityte',
            company: 'CEO',
            companyName: '3T-Transfers Technologies for Textile'
          },
          description:
          "ISPO Textrends unites different inspirations and encourages the 3T team to create unique solutions. 3T creates accessories for over a quarter of a century and currently it became a fruitful breeze-blowing eco-tech direction."        
        },
      ],
    },
  }
}
