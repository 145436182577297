// Images
import background from '../assets/images/covers/ispo-textrends-awards-background.svg'
import backgroundApparel from '../assets/images/cta/background-apparel.png'
import backgroundTextile from '../assets/images/cta/background-textile.png'
import backgroundDates from '../assets/images/ispo/key-dates-background-image.jpg'

import trendbook1 from '../assets/images/ispo/trendbook/ISPO-SS25_Mockup_0.jpg'
import trendbook2 from '../assets/images/ispo/trendbook/ISPO-SS25_Mockup_1.jpg'
import trendbook3 from '../assets/images/ispo/trendbook/ISPO-SS25_Mockup_2.jpg'
import trendbook4 from '../assets/images/ispo/trendbook/ISPO-SS25_Mockup_3.jpg'

import winners1 from '../assets/images/benefits-ispo/winners-ceremony-01.jpg'
import winners2 from '../assets/images/benefits-ispo/winners-ceremony-02.jpg'
import winners3 from '../assets/images/benefits-ispo/winners-ceremony-03.jpg'
import winners4 from '../assets/images/benefits-ispo/winners-ceremony-04.jpg'
import winners5 from '../assets/images/benefits-ispo/winners-ceremony-05.jpg'
import winners6 from '../assets/images/benefits-ispo/winners-ceremony-06.jpg'
import winners7 from '../assets/images/benefits-ispo/winners-ceremony-07.jpg'
import winners8 from '../assets/images/benefits-ispo/winners-ceremony-08.jpg'
import winners9 from '../assets/images/benefits-ispo/winners-ceremony-09.jpg'
import winners10 from '../assets/images/benefits-ispo/winners-ceremony-10.jpg'

import winnersTextile1 from '../assets/images/ispo/winners-textile/ss10-68630-multifunctional-fabric-serving-nature-and-sports-1.jpg'
import winnersTextile2 from '../assets/images/ispo/winners-textile/sl52-1836-circular-pigmentura-1.jpg'
import winnersTextile3 from '../assets/images/ispo/winners-textile/ol19-multisandwich-1.jpg'
import winnersTextile4 from '../assets/images/ispo/winners-textile/sl42-n2sa00843-1.jpg'
import winnersTextile5 from '../assets/images/ispo/winners-textile/se2-filie-ld221210a-1.jpg'
import winnersTextile6 from '../assets/images/ispo/winners-textile/bl5-eco-stretch-1.jpg'
import winnersTextile7 from '../assets/images/ispo/winners-textile/t14-eco-trac-1.jpg'
import winnersTextile8 from '../assets/images/ispo/winners-textile/bl47-polygiene-shedguard-1.jpg'
import winnersTextile9 from '../assets/images/ispo/winners-textile/in25-stealth-sheet-1.jpg'
import winnersTextile10 from '../assets/images/ispo/winners-textile/mc27-tdmw0946-terra-1.jpg'
import winnersTextile11 from '../assets/images/ispo/winners-textile/a2-dynapel-zipper-1.jpg'
import winnersTextile12 from '../assets/images/ispo/winners-textile/se18-aurora-illuminated-by-scs-1.jpg'

import winnersApparel1 from '../assets/images/ispo/winners-apparel/sb3-rz0010012-1.jpg'
import winnersApparel2 from '../assets/images/ispo/winners-apparel/pul7-carewear-by-leap-concept-1.jpg'
import winnersApparel3 from '../assets/images/ispo/winners-apparel/ds2-the-oncourt-set-responsible-activewear-1.jpg'
import winnersApparel4 from '../assets/images/ispo/winners-apparel/pt4-ccxi-travel-essentials-responsible-performance-lifestyle-1.jpg'
import winnersApparel5 from '../assets/images/ispo/winners-apparel/un3-male-absorbent-underwear-1.jpg'
import winnersApparel6 from '../assets/images/ispo/winners-apparel/jv7-otw-mens-base-layer-1.jpg'
import winnersApparel7 from '../assets/images/ispo/winners-apparel/ts1-the-cold-1.jpg'
import winnersApparel8 from '../assets/images/ispo/winners-apparel/pt3-the-smartstow-train-set-responsible-activewear-1.jpg'

// Badges
import badgeTextile from '../assets/images/ispo/badges/sticker-best-product-textile-edition.svg'
import badgeApparel from '../assets/images/ispo/badges/sticker-best-product-apparel-edition.svg'
import badgeObpTextile from '../assets/images/ispo/badges/sticker-obp-apparel-edition.svg'
import badgeObpApparel from '../assets/images/ispo/badges/sticker-obp-apparel-edition.svg'

export const ispoTextrendsAwardsContent = (t) => {
  return {
    cover: {
      background: background,
      title: t('ISPO_TEXTRENDS_AWARDS'),
      hideImageMobile: true,
      text: t('ISPO_TEXTRENDS_AWARDS_TEXT'),
      cta: {
        text: t('BOOK_A_MEETING'),
        //url: 'https://outlook.office.com/bookwithme/user/2759c61dec1e4fae9d1a395e3340d74c%40foursource.com/meetingtype/5RZExLNIeUyrG4ft7lLqUA2?anonymous',
        //url: 'https://ispotextrends.awardsplatform.com/',
        url: 'https://platform.foursource.com/signup?_ga=2.256824834.881639233.1700844454-1775659124.1700844454',
        newTab: true,
      },
    },
    video: {
      subhead: 'Learn More About The Jury Meeting',
      title: 'Behind The Scenes',
      platform: 'vimeo',
      id: '811982754',
    },
    ctaApparel: {
      title: t('APPAREL_EDITION'),
      subhead: t('ISPO_TEXTRENDS_AWARD'),
      bgImg: false,
      backgroundImage: backgroundApparel,
      buttons: [
        {
          text: t('SEE_MORE'),
          href: '/ispo-textrends-award/apparel-edition',
          color: 'white',
          variant: 'outlined',
        },
      ],
    },
    ctaTextile: {
      title: t('TEXTILE_EDITION'),
      subhead: t('ISPO_TEXTRENDS_AWARD'),
      bgImg: false,
      backgroundImage: backgroundTextile,

      buttons: [
        {
          text: t('SEE_MORE'),
          href: '/ispo-textrends-award/textile-edition',
          color: 'white',
          variant: 'outlined',
        },
      ],
    },
    trendbook: {
      slider: {
        className: 'swiper-images',
        loop: true,
        navigation: true,
        effect: 'fade',
        slides: [
          {
            id: 1,
            src: trendbook1,
            alt: 'ISPO Textrends Trendbook - Cover Image',
          },
          {
            id: 2,
            src: trendbook2,
            alt: 'ISPO Textrends Trendbook - Textile Edition',
          },
          {
            id: 3,
            src: trendbook3,
            alt: 'ISPO Textrends Trendbook - Apparel Edition',
          },
          {
            id: 4,
            src: trendbook4,
            alt: 'ISPO Textrends Trendbook - Trends',
          },
        ],
      },
    },
    columns: {
      direction: 'reverse',
      sizes: [6, 5],
      mode: 'light',
      heading: {
        title: t('BENEFITS_OF_PARTICIPATING'),
        subhead: t('FOR_SUPPLIERS'),
      },
      slider: {
        loop: true,
        navigation: true,
        effect: 'fade',
        slides: [
          {
            src: winners1,
          },
          {
            src: winners2,
          },
          {
            src: winners3,
          },
          {
            src: winners4,
          },
          {
            src: winners5,
          },
          {
            src: winners6,
          },
          {
            src: winners7,
          },
          {
            src: winners8,
          },
          {
            src: winners9,
          },
          {
            src: winners10,
          },
        ],
      },
      list: [
        t('BENEFITS_OF_PARTICIPATING_1'),
        t('BENEFITS_OF_PARTICIPATING_2'),
        t('BENEFITS_OF_PARTICIPATING_3'),
        t('BENEFITS_OF_PARTICIPATING_4'),
        t('BENEFITS_OF_PARTICIPATING_5'),
        t('BENEFITS_OF_PARTICIPATING_6'),
      ],
    },
    keyDates: {
      subhead: t('KEY_DATES'),
      subtitle: t('KEY_DATES_1') + t('KEY_DATES_2') + t('KEY_DATES_3') + t('KEY_DATES_4'),
      bg: {
        image: backgroundDates,
      },
    },
  }
}

export const textileObpProductsContent = (t) => {
  return {
    slider: {
      className: 'swiper-best-products swiper-center',
      slidesPerView: 'auto',
      spaceBetween: 24,
      simulateTouch: true,
      pagination: {
        clickable: true,
        dynamicBullets: true,
        renderBullet: function (index, className) {
          return `<span class="${className}"><span class="swiper-pagination-bullet-default"></span></span>`
        },
      },
      loop: false,
      autoplay: {
        pauseOnMouseEnter: true,
        disableOnInteraction: false,
      },
      navigation: true,
      slides: [
        {
          href: 'https://pro.foursource.com/intai-3',
          img: winnersApparel8,
          companyName: 'INTAI',
          productName: 'THE SMART-STOW TRAIN SET - Responsible Activewear',
          badge: badgeObpApparel,
        },
        {
          href: 'https://pro.foursource.com/yamamoto-corporation-2',
          img: winnersTextile12,
          companyName: 'Yamamoto Corporation',
          productName: 'AURORA illuminated by SCS',
          badge: badgeObpTextile,
        },
      ],
    },
  }
}

export const textileProductsContent = (t) => {
  return {
    slider: {
      className: 'swiper-best-products',
      slidesPerView: 'auto',
      spaceBetween: 24,
      simulateTouch: true,
      pagination: {
        clickable: true,
        dynamicBullets: true,
        renderBullet: function (index, className) {
          return `<span class="${className}"><span class="swiper-pagination-bullet-default"></span></span>`
        },
      },
      loop: true,
      autoplay: {
        pauseOnMouseEnter: true,
        disableOnInteraction: false,
      },
      navigation: true,
      slides: [
        {
          href: 'https://pro.foursource.com/a-sampaio-filhos-texteis-sa-2?_ga=2.199619502.1274484050.1700489266-738964008.1677164600',
          img: winnersTextile1,
          companyName: 'A. Sampaio & Filhos - Têxteis, S.A.',
          productName: '68630 ― Multifunctional Fabric: Serving Nature and Sports',
          badge: badgeTextile,
        },
        {
          href: 'https://pro.foursource.com/acatel-acabamentos-texteis-sa-1?_ga=2.168555301.547343060.1700464734-598931417.1696231709',
          img: winnersTextile2,
          companyName: 'Acatel, Acabamentos Texteis, SA',
          productName: '1836- Circular Pigmentura',
          badge: badgeTextile,
        },
        {
          href: 'https://pro.foursource.com/anta-sports-goods-group-co-ltd/products/334917',
          img: winnersTextile3,
          companyName: 'Anta (China) Co., Ltd.',
          productName: 'Multi-sandwich',
          badge: badgeTextile,
        },
        {
          href: 'https://pro.foursource.com/ashford-textiles-llc-2?_ga=2.259970954.1274484050.1700489266-738964008.1677164600',
          img: winnersTextile4,
          companyName: 'Ashford Textiles LLC',
          productName: 'N2SA00843',
          badge: badgeTextile,
        },
        {
          href: 'https://pro.foursource.com/chang-wong-industrial-co-ltd-3?_ga=2.160108601.547343060.1700464734-598931417.1696231709',
          img: winnersTextile5,
          companyName: 'Chang Wong Industrial Co., Ltd',
          productName: 'FILIE (LD221210A)',
          badge: badgeTextile,
        },
        {
          href: 'https://pro.foursource.com/hayleys-fabric-plc?_ga=2.27766972.1274484050.1700489266-738964008.1677164600',
          img: winnersTextile6,
          companyName: 'Hayleys Fabric PLC',
          productName: 'Eco Stretch',
          badge: badgeTextile,
        },
        {
          href: 'https://pro.foursource.com/maxim-label-packaging--3?_ga=2.259912842.1274484050.1700489266-738964008.1677164600',
          img: winnersTextile7,
          companyName: 'MAXIM-Korte Label & Packaging',
          productName: 'Eco Trac',
          badge: badgeTextile,
        },
        {
          href: 'https://pro.foursource.com/polygiene-group-ab-3?_ga=2.230612892.1274484050.1700489266-738964008.1677164600',
          img: winnersTextile8,
          companyName: 'Polygiene Group AB',
          productName: 'Polygiene ShedGuard',
          badge: badgeTextile,
        },
        {
          href: 'https://pro.foursource.com/takayama-co-ltd-3?_ga=2.199628590.1274484050.1700489266-738964008.1677164600',
          img: winnersTextile9,
          companyName: 'Takayama Co., Ltd.',
          productName: 'Stealth sheet',
          badge: badgeTextile,
        },
        {
          href: 'https://pro.foursource.com/thomas-dynamic-material-jiangsu-co-ltd-2?_ga=2.236959745.1274484050.1700489266-738964008.1677164600',
          img: winnersTextile10,
          companyName: 'Thomas Dynamic Material (Jiangsu) Co., Ltd.',
          productName: 'TDMW-0946/Terra',
          badge: badgeTextile,
        },
        {
          href: 'https://pro.foursource.com/ykk-europe-limited?_ga=2.190258987.1274484050.1700489266-738964008.1677164600',
          img: winnersTextile11,
          companyName: 'YKK Europe Ltd Germany Branch',
          productName: 'DynaPel Zipper',
          badge: badgeTextile,
        },
      ],
    },
  }
}

export const apparelProductsContent = (t) => {
  return {
    slider: {
      className: 'swiper-best-products swiper-navigation-green',
      spaceBetween: 24,
      slidesPerView: 'auto',
      centerInsufficientSlides: true,
      pagination: {
        clickable: true,
        dynamicBullets: true,
        renderBullet: function (index, className) {
          return `<span class="${className}"><span class="swiper-pagination-bullet-default"></span></span>`
        },
      },
      navigation: true,
      slides: [
        {
          href: 'https://pro.foursource.com/chia-her-industrial-co-ltd-2-1?_ga=2.229776408.547343060.1700464734-598931417.1696231717',
          img: winnersApparel1,
          companyName: 'Chia Her Industrial Co., Ltd.',
          productName: "RZ0010-012",
          badge: badgeApparel,
        },
        {
          href: 'https://pro.foursource.com/leap-concept-2?_ga=2.230805659.547343060.1700464734-598931417.1696231709',
          img: winnersApparel2,
          companyName: 'ETIC-DESIGN CO.E.S.P.J. / Leap Concept',
          productName: 'Care-Wear by Leap Concept',
          badge: badgeApparel,
        },
        {
          href: 'https://pro.foursource.com/intai-3?_ga=2.197000108.1274484050.1700489266-738964008.1677164600',
          img: winnersApparel3,
          companyName: 'INTAI',
          productName: 'THE ON-COURT SET - Responsible Activewear',
          badge: badgeApparel,
        },
        {
          href: 'https://pro.foursource.com/intai-3?_ga=2.197000108.1274484050.1700489266-738964008.1677164600',
          img: winnersApparel4,
          companyName: 'INTAI',
          productName: 'CCxi TRAVEL ESSENTIALS - Responsible Performance Lifestyle',
          badge: badgeApparel,
        },
        {
          href: 'https://pro.foursource.com/mas-innovation-pvt-ltd-3?_ga=2.189783979.1274484050.1700489266-738964008.1677164600',
          img: winnersApparel5,
          companyName: 'MAS Innovation (Pvt) Ltd',
          productName: 'Male Absorbent Underwear',
          badge: badgeApparel,
        },
        {
          href: 'https://pro.foursource.com/sudwolle-group-2?_ga=2.261036941.1274484050.1700489266-738964008.1677164600',
          img: winnersApparel6,
          companyName: 'Südwolle Group GmbH',
          productName: 'OTW® Men’s Base Layer',
          badge: badgeApparel,
        },
        {
          href: 'https://pro.foursource.com/yaka-tekstil-san-ve-tic-a?_ga=2.261084685.1274484050.1700489266-738964008.1677164600',
          img: winnersApparel7,
          companyName: 'Yaka Tekstil',
          productName: 'The C.O.L.D.',
          badge: badgeApparel,
        },
      ],
    },
  }
}
